import { Layout, RichText } from "@components";
import {
    ContactSection,
    DocumentList,
    Footer,
    Section,
    TeamProfilesList,
} from "@containers";
import { Person, RouteLocation, Theme } from "@interfaces";
import { graphql } from "gatsby";
import React from "react";

interface Profile {
    name: string;
    person: { slug: string };
}

interface TeamProfile {
    id: string;
    teamProfile: Profile;
}

interface TeamPage {
    slug: string;
    teamProfiles: TeamProfile[];
    introText: {
        editor: {
            raw: string;
        };
    };
    contactPerson: Person;
    timeSlots: unknown;
    internalLabel: string;
    theming: Theme;
    documents: unknown;
    languages: string;
}

interface ContentfulTeamPage {
    contentfulTeamPage: TeamPage;
}

interface Props {
    data: ContentfulTeamPage;
    pageContext: {
        locale: string;
    };
    location: RouteLocation;
}

const TeamPageTemplate = ({
    data,
    location,
    pageContext: { locale },
}: Props): JSX.Element => {
    const {
        contentfulTeamPage: {
            slug,
            teamProfiles,
            introText,
            timeSlots,
            internalLabel,
            theming,
            contactPerson,
            documents,
            languages,
        },
    } = data;

    return (
        <Layout
            lang={locale}
            teamLanguage={languages ?? "all"}
            title={internalLabel}
            theme={theming}
            location={location}
        >
            <main>
                <div className="container-lg">
                    <Section>
                        {introText && introText.editor && (
                            <RichText rawText={introText.editor} />
                        )}
                    </Section>
                    {documents && <DocumentList documents={documents} />}
                    <TeamProfilesList
                        teamProfiles={teamProfiles}
                        teamSlug={slug}
                    />
                    <ContactSection
                        teamName={internalLabel}
                        teamProfiles={teamProfiles}
                        timeSlots={timeSlots}
                    />
                </div>
                <Footer theme={theming} contactPerson={contactPerson} />
            </main>
        </Layout>
    );
};
export default TeamPageTemplate;
export const query = graphql`
    query($id: String!) {
        contentfulTeamPage(id: { eq: $id }) {
            id
            slug
            internalLabel
            introText {
                editor {
                    raw
                }
            }
            languages
            contactPerson: contactPerson {
                ...ContactPerson
            }
            teamProfiles: teamProfiles {
                id
                name
                person {
                    ...Person
                    id
                    slug
                    region
                    portrait {
                        file {
                            url
                        }
                        gatsbyImageData(
                            width: 40
                            layout: FIXED
                            placeholder: BLURRED
                        )
                    }
                }
            }
            timeSlots: timeSlots {
                id
                date
                startTime
                endTime
            }
            theming: theming {
                ...Theming
            }
            documents {
                id
                file {
                    url
                    fileName
                    contentType
                    details {
                        size
                    }
                }
            }
        }
    }
`;
